import { useState } from 'react';
import { Box, Stack, Button, useMediaQuery } from '@mui/material';
import { TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions}from '@mui/material';
import { FormGroup, FormLabel, FormControlLabel, Checkbox } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom'

const theme = createTheme();

export default Landing;

function Landing() {
  const navigateTo = useHistory().push;
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [modelOpen, setModelOpen] = useState(false)
  const closeModel =()=>{
    setModelOpen(false)
  }

  let imagesize = "95vw"
  if (largeScreen){imagesize = "65vw"}

  return (
    <>
      <Stack alignItems='center' justifyContent='center' sx={{height:'100vh', background:'black'}}>
        <Box sx={{position:'relative'}}>
          <Box component="img" sx={{display:"block", width:imagesize}} src="PartyAtTheBarn.jpg"></Box>
          <Box component="img" sx={{position:"absolute", top:0, right:0, display:"block", width:'30%', height:'auto'}} src="CountryThunder.png"></Box>
        </Box>
        <Button onClick={()=>{setModelOpen(true)}} variant="contained" size="large" color="success" sx={{margin:2}}>I'm Going!</Button>
      </Stack>
      <SignUpDialog isOpen={modelOpen} handleClose={closeModel}/>
    </>
  );
}



function SignUpDialog({isOpen, handleClose}){

  return(
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Party at the Barn!</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Please fill out this form, so we can better plan for who is going.
          </DialogContentText>
          <TextField required autoFocus fullWidth id="name" label="Name" margin="dense"></TextField>
          <TextField required fullWidth id="email" label="Email Address" autoComplete="email" margin="dense"/>
          <DialogContentText>
          Days I'll be there
          </DialogContentText>
          <FormControlLabel control={<Checkbox />} label="Wednesday" />
          <FormControlLabel control={<Checkbox />} label="Thursday" />
          <FormControlLabel control={<Checkbox />} label="Friday" />
          <FormControlLabel control={<Checkbox />} label="Saturday" />
          <FormControlLabel control={<Checkbox />} label="Sunday" />
          <FormControlLabel control={<Checkbox />} label="Monday" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" variant="contained" size="large" sx={{margin:2}}>Cancel</Button>
          <Button onClick={()=>{}} color="success" variant="contained" size="large" sx={{margin:2}}>Submit</Button>      
        </DialogActions>
      </Dialog>
    </>
  )
}
