import logo from './logo.svg';
import './App.css';

import {} from '@mui/material'
import { Paper, Grid, CssBaseline, Box, Stack, Button, useMediaQuery } from '@mui/material';
import { BrowserRouter, Route, Switch as Routes } from 'react-router-dom';

import Landing from './pages/Landing'

function App() {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route key="Landing" path = "/" render={() => <Landing/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
